<div class="main-content body-bg p-6 user-listing">
  <div>
    <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
      User Management
    </h4>
    <p
      class="medium mat-body-1 text textXS mb-6"
      style="margin-top: 0"
      *ngIf="!isRolePermissionTabActive"
    >
      Add, Edit and delete users
    </p>
    <p
      class="medium mat-body-1 text textXS mb-6"
      style="margin-top: 0"
      *ngIf="isRolePermissionTabActive"
    >
      Manage your user and permission.
    </p>
  </div>
  <div class="account-detail-tab management">
    <div>
      <mat-tab-group
        animationDuration="0ms"
        [selectedIndex]="isRolePermissionTabActive ? 1 : 0"
        (selectedTabChange)="onTabChange($event)"
      >
        <mat-tab label="Users">
          <div class="pb-4 pt-4">
            <div
              class="d-flex justify-between align-center account-detail-actions"
            >
              <div class="">
                <mat-form-field
                  appearance="outline"
                  class="search-input user"
                  [formGroup]="form"
                >
                  <input
                    type="text"
                    matInput
                    placeholder="Search"
                    formControlName="searchQuery"
                  />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>
              <div class="d-flex align-center">
                <sft-button
                  [label]="'Add New User'"
                  [color]="'primary'"
                  [variant]="'flat'"
                  [icon]="'home'"
                  [size]="'xl'"
                  (buttonClick)="openAddDialog()"
                ></sft-button>
              </div>
            </div>
          </div>
          <app-loader *ngIf="loading"></app-loader>
          <div
            *ngIf="!loading && userList.length === 0"
            class="required-star"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: calc(100vh - 200px);
              font-size: 20px;
            "
          >
            No users found!
          </div>
          <div
            class="account-detail-table overflow-auto user-listing-wrapper"
            *ngIf="!loading && userList.length > 0"
          >
            <table
              mat-table
              [dataSource]="userList"
              matSort
              (matSortChange)="onUserListSort($event)"
            >
              <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="text textS">
                  Sno.
                </th>
                <td
                  mat-cell
                  *matCellDef="let i = index"
                  class="grey-text textS"
                >
                  #{{ i + 1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="name text textS"
                  mat-sort-header
                >
                  Name
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS name-col table-ellipse"
                  title="{{ element.name ? element.name : '--' }}"
                >
                  {{ element.name  || "--" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="email text textS"
                  mat-sort-header="email"
                >
                  Email
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS table-ellipse email-col"
                  title="{{ element.email || '--' }}"
                >
                  {{ element.email | truncateEmail : 25 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="contact text textS role"
                  mat-sort-header
                >
                  Role
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS name-col"
                  title="{{ element?.role?.title || '--' }}"
                >
                {{ element?.role?.title ? element.role.title : "--" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="portal">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="contact text textS"
                  mat-sort-header="portolType"
                >
                  Portal Type
                </th>
                <td mat-cell *matCellDef="let element" class="grey-text textS">
                  {{
                    element.portolType === "admin"
                      ? "Admin"
                      : element.portolType === "customer"
                      ? "Customer"
                      : "--"
                  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="status text textS"
                  mat-sort-header
                >
                  Status
                </th>
                <td mat-cell *matCellDef="let element" class="textS">
                  <div
                    *ngIf="isStatusActive(element.status)"
                    class="w-fit px-2 table-chip active-status"
                  >
                    {{ getFormattedStatus(element.status) }}
                  </div>
                  <div
                    *ngIf="isStatusDeactivated(element.status)"
                    class="w-fit px-2 table-chip deactivated-status"
                  >
                    {{ getFormattedStatus(element.status) }}
                  </div>
                  <div *ngIf="!element.status" class="textS">--</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="lastUpdated">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="updated text textS"
                  mat-sort-header="modifiedAt"
                >
                  Last Updated
                </th>
                <td mat-cell *matCellDef="let element" class="grey-text textS">
                  {{
                    element.modifiedAt
                      ? (element.modifiedAt | date : "d MMM y h:mm a" : "UTC")
                      : "--"
                  }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action text textS">
                  Action
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text text-center textS"
                >
                  <mat-icon
                    [matMenuTriggerFor]="menuAction"
                    style="cursor: pointer"
                    >more_vert</mat-icon
                  >
                  <mat-menu #menuAction="matMenu">
                    <button
                      mat-menu-item
                      class="textS text"
                      (click)="openEditDialog(element)"
                    >
                      Edit
                    </button>
                    <button
                      mat-menu-item
                      class="textS text"
                      (click)="toggleUserStatus(element)"
                    >
                      {{
                        element.status === "active" ? "Deactivate" : "Activate"
                      }}
                    </button>
                    <button
                      mat-menu-item
                      class="textS text"
                      (click)="openPasswordDialog(element)"
                    >
                      Change Password
                    </button>
                  </mat-menu>
                </td>
              </ng-container>
              <tr
                mat-header-row
                class="account-detail-header"
                *matHeaderRowDef="displayedUsersColumns"
              ></tr>
              <tr
                mat-row
                class="account-detail-body"
                *matRowDef="let row; columns: displayedUsersColumns"
              ></tr>
            </table>
            <mat-paginator
              class="action-table-pagination fixed bottom-0 resource-listing"
              [length]="totalRecords"
              [pageSize]="userPageSize"
              [pageIndex]="userPageNumber"
              [pageSizeOptions]="[25, 50, 75, 100]"
              (page)="onUserPageChange($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </mat-tab>
        <mat-tab label="Role & Permission">
          <div class="pb-6 pt-6">
            <div
              class="d-flex justify-between align-center account-detail-actions"
            >
              <div class="">
                <mat-form-field
                  appearance="outline"
                  class="search-input user"
                  [formGroup]="roleForm"
                >
                  <input
                    type="text"
                    matInput
                    placeholder="Search"
                    formControlName="searchQuery"
                  />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>
              <div class="d-flex align-center">
                <sft-button
                  [label]="'Add New Role'"
                  [color]="'primary'"
                  [variant]="'flat'"
                  [icon]="'home'"
                  [size]="'xl'"
                  [routerLink]="'/permission/manage/'"
                ></sft-button>
              </div>
            </div>
          </div>

          <app-loader *ngIf="loading"></app-loader>
          <div
            *ngIf="!loading && roleAndPermission.length === 0"
            class="required-star"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: calc(100vh - 200px);
              font-size: 20px;
            "
          >
            No Role & Permission List found!
          </div>
          <div
            class="account-detail-table overflow-auto roles-listing-wrapper"
            *ngIf="!loading && roleAndPermission.length > 0"
          >
            <table
              mat-table
              [dataSource]="roleAndPermission"
              matSort
              style="cursor: pointer"
              (matSortChange)="onRoleListSort($event)"
            >
              <ng-container matColumnDef="sno">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text textS serial-no"
                >
                  Sno.
                </th>
                <td
                  mat-cell
                  *matCellDef="let i = index"
                  class="grey-text textS"
                >
                  {{ i + 1 }}
                </td>
              </ng-container>
              <ng-container matColumnDef="role">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text textS name"
                  mat-sort-header="title"
                >
                  Role
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS name-col table-ellipse"
                  title="{{ element.title }}"
                  (click)="editRole(element)"
                >
                  {{ element.title  || "--" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text textS Description"
                  mat-sort-header
                >
                  Description
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS Description-col table-ellipse name-col"
                  (click)="editRole(element)"
                  title="{{ element.description }}"
                >
                  {{ element.description || "--" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="createdAt">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text textS contact"
                  mat-sort-header
                >
                  Created At
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS"
                  (click)="editRole(element)"
                >
                  {{ element.createdAt | date : "d MMM y h:mm a" : "UTC" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="lastUpdated">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text textS updated"
                  mat-sort-header="modifiedAt"
                >
                  Last Updated
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text textS"
                  (click)="editRole(element)"
                >
                  {{ element.modifiedAt | date : "d MMM y h:mm a" : "UTC" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action text textS">
                  Action
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="grey-text text-center"
                >
                  <mat-icon
                    [matMenuTriggerFor]="menuAction"
                    style="cursor: pointer"
                    >more_vert</mat-icon
                  >
                  <mat-menu #menuAction="matMenu">
                    <button
                      mat-menu-item
                      class="textS text"
                      (click)="editRole(element)"
                    >
                      Edit
                    </button>
                  </mat-menu>
                </td>
              </ng-container>
              <tr
                mat-header-row
                class="account-detail-header"
                *matHeaderRowDef="displayedRolesAndPermissionsColumns"
              ></tr>
              <tr
                mat-row
                class="account-detail-body"
                *matRowDef="
                  let row;
                  columns: displayedRolesAndPermissionsColumns
                "
              ></tr>
            </table>
            <mat-paginator
              class="action-table-pagination fixed bottom-0 resource-listing"
              [length]="totalRoleRecords"
              [pageSize]="rolePageSize"
              [pageIndex]="rolePageNumber"
              [pageSizeOptions]="[25, 50, 75, 100]"
              (page)="onRolePageChange($event)"
              aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementDialogComponent } from 'src/shared/components/dialog/admin-dialogs/user-management-dialog/user-management-dialog.component';
import { UserManagementService } from '../../services/user-management.service';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { ChangePasswordtDialogComponent } from 'src/shared/components/dialog/admin-dialogs/change-password-dialog/change-password-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';

export interface Users {
  userID: string;
  no: string;
  name: string;
  email: string;
  contact: string;
  role: string;
  portal: string;
  lastUpdated: string;
  status: string;
  action: string;
}
export interface RoleAndPermission {
  sno: string;
  role: string;
  description: string;
  createdAt: string;
  lastUpdated: string;
  action: string;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-listing.component.html',
})
export class UserManagementComponent implements OnInit {
  loading: boolean = false;
  filteredResources: any[] = [];
  userList: any[] = [];
  roleAndPermission: any[] = [];
  form!: FormGroup;
  roleForm!: FormGroup;
  userPageSize: number = 25;
  rolePageSize: number = 25;
  userPageNumber: number = 0;
  rolePageNumber: number = 0;
  search: string = '';
  sortBy: 'name' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'DESC';
  lastSortBy: string = '';
  totalRecords: number = 0;
  totalRoleRecords: number = 0;
  isRolePermissionTabActive: boolean = false;
  canManageResources: boolean = false;

  constructor(
    public dialog: MatDialog,
    private userService: UserManagementService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  displayedUsersColumns: string[] = [
    'no',
    'name',
    'email',
    'role',
    'portal',
    'status',
    'lastUpdated',
    'action',
  ];
  dataSource = new MatTableDataSource<Users>([]);

  displayedRolesAndPermissionsColumns: string[] = [
    'sno',
    'role',
    'description',
    'createdAt',
    'lastUpdated',
    'action',
  ];
  dataSource1 = new MatTableDataSource<RoleAndPermission>([]);

  ngOnInit(): void {
    this.form = this.formbuilder.group({
      searchQuery: [''],
    });

    this.roleForm = this.formbuilder.group({
      searchQuery: [''],
    });

    this.searchUsers();
    this.searchRoles();

    this.route.fragment.subscribe((fragment) => {
      if (fragment === 'role-permission') {
        this.isRolePermissionTabActive = true;
        this.getRolePermissionList(
          this.rolePageNumber,
          this.rolePageSize,
          this.search,
          this.sortBy,
          this.orderBy
        );
      } else {
        this.getUserList(
          this.userPageNumber,
          this.userPageSize,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  onTabChange(event: any) {
    if (event.tab.textLabel === 'Role & Permission') {
      this.isRolePermissionTabActive = true;
      this.search = '';
      this.getRolePermissionList(
        this.rolePageNumber,
        this.rolePageSize,
        this.search,
        this.sortBy,
        this.orderBy
      );
    } else {
      this.isRolePermissionTabActive = false;
      this.search = '';
      this.getUserList(
        this.userPageNumber,
        this.userPageSize,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(UserManagementDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getUserList(
          this.userPageNumber,
          this.userPageSize,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  isStatusActive(status: string): boolean {
    return !!status && status.toLowerCase() === 'active';
  }

  isStatusDeactivated(status: string): boolean {
    return !!status && status.toLowerCase() === 'de_active';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Active';
      case 'de_active':
        return 'Deactivated';
      default:
        return '';
    }
  }

  openPasswordDialog(element: any): void {
    const dialogRef = this.dialog.open(ChangePasswordtDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, userId: element.userID },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getUserList(
          this.userPageNumber,
          this.userPageSize,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(UserManagementDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, element: element },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getUserList(
          this.userPageNumber,
          this.userPageSize,
          this.search,
          this.sortBy,
          this.orderBy
        );
      }
    });
  }

  onUserListSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.userPageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.userService
      .getUserList(
        this.userPageNumber,
        this.userPageSize,
        this.search,
        sortBy,
        validOrderBy
      )
      .subscribe({
        next: (data: any) => {
          this.userList = data?.data?.records || [];
          this.dataSource.data = this.userList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in User List request:', error);
        },
      });
  }

  onUserPageChange(event: PageEvent): void {
    this.userPageNumber = event.pageIndex;
    this.userPageSize = event.pageSize;
    this.getUserList(
      this.userPageNumber,
      this.userPageSize,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  getUserList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.userService
      .getUserList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data) => {
          this.userList = data?.data?.records;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.userList.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
        },
        error: (error) => {
          console.error('Error fetching user list:', error);
          this.loading = false;
        },
      });
  }

  searchUsers(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        if (!this.isRolePermissionTabActive) {
          this.getUserList(
            this.userPageNumber,
            this.userPageSize,
            this.search,
            this.sortBy,
            this.orderBy
          );
        }
      });
  }

  toggleUserStatus(user: Users): void {
    const newStatus = user.status === 'active' ? 'de_active' : 'active';
    const body = { userID: user.userID, status: newStatus };

    this.userService.updateStatus(body).subscribe({
      next: () => {
        this.getUserList(
          this.userPageNumber,
          this.userPageSize,
          this.search,
          this.sortBy,
          this.orderBy
        );
      },
      error: (error: any) => {
        console.error('Error updating user status:', error);
      },
    });
  }

  onRolePageChange(event: PageEvent): void {
    this.rolePageNumber = event.pageIndex;
    this.rolePageSize = event.pageSize;
    this.getRolePermissionList(
      this.rolePageNumber,
      this.rolePageSize,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  getRolePermissionList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ) {
    this.loading = true;
    this.userService
      .getRolePermissionList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data) => {
          this.roleAndPermission = data?.data?.records;
          this.totalRoleRecords = data?.data?.count || 0;
          this.loading = false;
          this.roleAndPermission.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });
        },
        error: (error) => {
          console.error('Error fetching user list:', error);
          this.loading = false;
        },
      });
  }

  onRoleListSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.rolePageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.userService
      .getRolePermissionList(
        this.rolePageNumber,
        this.rolePageSize,
        this.search,
        sortBy,
        validOrderBy
      )
      .subscribe({
        next: (data: any) => {
          this.roleAndPermission = data?.data?.records || [];
          this.dataSource.data = this.roleAndPermission;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Role List request:', error);
        },
      });
  }

  searchRoles(): void {
    this.roleForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        if (this.isRolePermissionTabActive) {
          this.getRolePermissionList(
            this.rolePageNumber,
            this.rolePageSize,
            this.search,
            this.sortBy,
            this.orderBy
          );
        }
      });
  }

  editRole(element: any): void {
    this.router.navigate(['/permission/manage/', element.userRoleID]);
  }
}

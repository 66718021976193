import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '../../services/user-management.service';
import { Observable, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

interface Permission {
  createdAt: string;
  deletedAt: string | null;
  description: string;
  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string | null;
  permissionID: string;
  type: string;
}

interface RoleAndPermission {
  createdAt: string;
  deletedAt: string | null;
  description: string;
  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string | null;
  moduleID: string;
  name: string;
  permissions: Permission[];
}

@Component({
  selector: 'app-manage-role-permission',
  templateUrl: './manage-role-permission.component.html',
})
export class ManageRolePermissionComponent implements OnInit {
  id!: string;
  title: string = '';
  description: string = '';
  roleForm!: FormGroup;
  isEditMode: boolean = false;
  loading: boolean = false;
  roleModules: RoleAndPermission[] = [];
  isButtonDisabled: boolean = true;
  canManageResources: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserManagementService,
    private globalService: GlobalService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const cost_centerPermissions = userPermissions.find(
        (p: any) => p.module === 'permission'
      );
      if (
        cost_centerPermissions &&
        cost_centerPermissions.permission.includes('view') &&
        cost_centerPermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.id = this.route.snapshot.params['id'];
    this.initializeForm();
    this.subscribeToRouteParams();
    this.getRoleModules();
    this.roleForm.valueChanges.subscribe(() => {
      this.isButtonDisabled = this.roleForm.invalid || !this.roleForm.dirty;
    });
    this.isButtonDisabled = true;
  }

  displayedColumns: string[] = ['name', 'view', 'manage'];
  dataSource: RoleAndPermission[] = [];

  getRoleModules(): void {
    if (this.isEditMode) {
      // If in edit mode, send the ID
      this.userService
        .getRoleModules(this.id)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: (data) => {
            this.roleModules = data?.data?.records;
          },
          error: (error) => {
            console.error('Error fetching role modules:', error);
          },
        });
    } else {
      // If not in edit mode, do not send the ID
      this.userService
        .getRoleModules('')
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: (data) => {
            this.roleModules = data?.data?.records;
            this.loading = false;
          },
          error: (error) => {
            console.error('Error fetching role modules:', error);
            this.loading = false;
          },
        });
    }
  }

  subscribeToRouteParams(): void {
    this.route.params.subscribe((params) => {
      const userRoleID = params['id'];
      if (userRoleID) {
        this.isEditMode = true;
        this.fetchRoleData(userRoleID)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: (roleDetails: any) => {
              this.patchForm(roleDetails?.data);
            },
            error: (error: any) => {
              console.error('Error fetching role details:', error);
            },
          });
      }
    });
  }

  fetchRoleData(userRoleID: string): Observable<any> {
    return this.userService.getRoleById(userRoleID);
  }

  patchForm(roleDetails: any): void {
    try {
      if (roleDetails) {
        this.roleForm.patchValue({
          title: roleDetails.title,
          description: roleDetails.description,
        });
      }
    } catch (error) {
      console.error(
        'An error occurred while patching form with role details:',
        error
      );
    }
  }

  initializeForm(): void {
    this.roleForm = this.fb.group({
      title: ['', [Validators.required, whitespaceValidator()]],
      description: ['', [Validators.required, whitespaceValidator()]],
    });
  }

  updateOrSave(): void {
    if (this.isEditMode) {
      this.updateRole();
    } else {
      this.createRole();
    }
  }

  onCheckboxChange(
    type: string,
    element: RoleAndPermission,
    isChecked: boolean
  ): void {
    const permission = element.permissions.find(
      (permission) => permission.type === type
    );
    if (!permission) {
      console.error('Permission not found for type:', type);
      return;
    }

    const formData = {
      permissionID: permission.permissionID,
      userRoleID: this.id,
    };

    if (!isChecked) {
      // If checkbox is checked, assign the permission
      this.userService
        .assignPermission(formData)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.getRoleModules();
          },
          error: (error: any) => {
            console.error('Error assigning permission:', error);
          },
        });
    } else {
      // If checkbox is unchecked, unassign the permission
      this.userService
        .unAssignPermission(formData)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.getRoleModules();
            this.loading = false;
          },
          error: (error: any) => {
            console.error('Error unassigning permission:', error);
            this.loading = false;
          },
        });
    }
  }

  updateRole(): void {
    this.isEditMode = true;
    const updatedData = {
      ...this.roleForm.getRawValue(),
      userRoleID: this.id,
    };

    this.userService
      .updateRole(updatedData)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: () => {
          this.router.navigate(['/user-management'], {
            fragment: 'role-permission',
          });
        },
        error: (error: any) => {
          console.error('Error updating role:', error);
        },
      });
  }

  createRole() {
    if (this.title && this.description) {
      const formData = {
        title: this.title,
        description: this.description,
      };
      this.userService
        .addRoleAndPermission(formData)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.router.navigate(['/user-management'], {
              fragment: 'role-permission',
            });
            this.clearInputFields();
            this.router.navigate(['/user-management'], {
              fragment: 'role-permission',
            });
          },
          error: (error) => {
            console.error('Error adding role and permission:', error);
          },
        });
    } else {
      console.error('Role title and description are required.');
    }
  }

  onSaveClick(): void {
    if (this.roleForm.valid) {
      const formData = this.roleForm.value;
      if (formData.userRoleID && this.isEditMode) {
        this.updateRole();
      } else {
        this.createRole();
      }
      this.markFormGroupTouched(this.roleForm);
    } else {
      this.markFormGroupTouched(this.roleForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  clearInputFields() {
    this.title = '';
    this.description = '';
  }

  goBack() {
    this.router.navigate(['/user-management'], { fragment: 'role-permission' });
  }
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { SftInputModule } from 'src/stories/components/Input/input.module';
import { AccountComponent } from 'src/stories/components/account-list/account.component';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { UserManagementComponent } from './components/user-listing/user-listing.component';
import { RouterModule } from '@angular/router';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { CommonModule } from '@angular/common';
import { ManageRolePermissionComponent } from './components/manage-role-permission/manage-role-permission.component';
@NgModule({
  declarations: [UserManagementComponent, ManageRolePermissionComponent],
  imports: [
    CommonModule,
    LoaderComponent,
    FormsModule,
    AngularMaterialModule,
    SidebarModule,
    AccountComponent,
    SftButtonModule,
    SftInputModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    UserManagementRoutingModule,
  ],
})
export class UserManagementModule {}

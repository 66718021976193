<div class="main-content body-bg listing-wrapper" [formGroup]="roleForm">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text mb-1">All Permissions</h4>
        <sft-button
          [label]="
            'Add & Update role and permission.
          '
          "
          [color]="'primary'"
          className="grey-text-btn textXS p-0"
          [variant]="'default'"
          [disable]="false"
          [withIcon]="true"
          [icon]="'keyboard_backspace'"
          [disableRipple]="false"
          [size]="'small'"
          (buttonClick)="goBack()"
        ></sft-button>
      </div>
    </div>
    <div
      class="body-bg listing-content overflow-auto craft-listing permission-listing-wrapper p-4"
    >
      <div class="role-info mb-4">
        <h3 class="text mat-headline-5 mb-4">Role</h3>
        <div class="row">
          <div class="col s13 m3 l3">
            <sft-input
              [label]="'Role title'"
              [readonly]="false"
              [placeholder]="'Enter here'"
              [disable]="false"
              [required]="true"
              [infoIcon]="false"
              [infoTitle]="'Title'"
              formControlName="title"
              [(ngModel)]="title"
            ></sft-input>
          </div>
          <div class="col s12 m6 l9">
            <div class="d-flex align-end">
              <sft-input
                [label]="'Description'"
                [readonly]="false"
                [placeholder]="'Enter here'"
                class="w-full"
                [disable]="false"
                [required]="true"
                [infoIcon]="false"
                [infoTitle]="'Title'"
                formControlName="description"
                [(ngModel)]="description"
              ></sft-input>
              <sft-button
                [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
                [label]="isEditMode ? 'Update' : 'Save'"
                [color]="'primary'"
                [variant]="'flat'"
                className="ml-3 px-8"
                [size]="'xl'"
                [disable]="isButtonDisabled"
                (click)="isButtonDisabled ? null : updateOrSave()"
              ></sft-button>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="id">
        <h4 *ngIf="roleModules.length > 0" class="mat-subtitle-1 text fw-400">
          Permissions
        </h4>
        <app-loader *ngIf="loading"></app-loader>

        <table
          mat-table
          [dataSource]="roleModules"
          class="custom-action-table overflow-auto"
          *ngIf="!loading && roleModules.length > 0"
        >
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="module-col text-black">
              Module
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="text"
              [ngSwitch]="element.name"
            >
              <ng-container *ngSwitchCase="'dashboard'">Dashboard</ng-container>
              <ng-container *ngSwitchCase="'account'">Account</ng-container>
              <ng-container *ngSwitchCase="'cost_center'"
                >Cost Center</ng-container
              >
              <ng-container *ngSwitchCase="'project'">Project</ng-container>
              <ng-container *ngSwitchCase="'resource'">Resource</ng-container>
              <ng-container *ngSwitchCase="'invoice'">Invoice</ng-container>
              <ng-container *ngSwitchCase="'core_team'">Core Team</ng-container>
              <ng-container *ngSwitchCase="'open_position'"
                >Open Position</ng-container
              >
              <ng-container *ngSwitchCase="'user_management'"
                >User Management</ng-container
              >
              <ng-container *ngSwitchCase="'activity_log'"
                >Activity Log</ng-container
              >
              <ng-container *ngSwitchCase="'role_permission'"
                >Role & Permissions</ng-container
              >
              <ng-container *ngSwitchCase="'re_occurring_expense'"
                >Re-occurring Expense</ng-container
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="view">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="Permission-col text-black"
            >
              Permission
            </th>
            <td mat-cell *matCellDef="let element" class="text">
              <ng-container *ngIf="element.permissions?.[0]?.type === 'view'">
                <mat-checkbox
                  [checked]="element?.permissions?.[0]?.access"
                  (change)="onCheckboxChange('view', element, element?.permissions?.[0]?.access)"
                ></mat-checkbox>
                <span>View</span>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="manage">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="Permission-col w-full"
            ></th>
            <td mat-cell *matCellDef="let element" class="text">
              <ng-container *ngIf="element.permissions?.[1]?.type === 'manage'">
                <mat-checkbox
                  [checked]="element?.permissions?.[1]?.access"
                  (change)="onCheckboxChange('manage', element, element?.permissions?.[1]?.access)"
                ></mat-checkbox>
                <span>Manage</span>
              </ng-container>
            </td>
          </ng-container>
          <tr
            mat-header-row
            class="action-table-header-data"
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            mat-row
            class="action-table-body-data"
            *matRowDef="let row; columns: displayedColumns"
          ></tr>
        </table>
      </ng-container>
    </div>
  </div>
</div>
